@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.root {
    padding: 6px 12px;
    border-radius: 10px;
    cursor: default;
    position: relative;

    @include borderColorWO(5px);
}

.headerCard {
    @include flex(row, space-between, flex-start);
    gap: 8px;

    margin-bottom: 5px;

    img {
        width: 24px;
        height: 24px;
    }
}

.cardNameWrap {
    @include flex(row, flex-start, center);
    gap: 8px;
}
