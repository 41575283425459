.inputIconWrap {
    height: 100%;
    margin-right: 8px;
}

.sendIconWrap {
    height: 100%;
    left: 8px;

    cursor: pointer;
}

.noteEditWrap {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
}

.dropFileZone {
    color: #848a9b;
    font-family: Poppins, sans-serif;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 82px;
    border-radius: 10px;

    background: #f4f5f7;

    border: 2px dashed #e9eef3;
}

.attachFileWrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;

        gap: 5px;
        color: #1b6bc0;
        font-family: Poppins, sans-serif;
        font-size: 13px;
    }
}
