.spanProgressText {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
}

.position-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progressBarRoot {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2000;

    display: flex;
    align-items: center;
    justify-content: center;
}
