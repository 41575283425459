.root {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 14px;

    & .modeBtn {
        cursor: pointer;
        width: 30px;
        height: 30px;
        color: #bcbcc2;

        &:hover {
            color: #a2a8ad;
        }
        &.active {
            background: #e2e6eb;
        }
    }
}
