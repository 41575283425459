@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.internalHeader {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 2.625rem;
    gap: 1.2rem;
    padding-right: 1.5rem;
    border-bottom: 1px solid #f2f6f8;
    box-sizing: border-box;
}

.alert {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
        content: '';
        width: 1px;
        height: 1.5rem;
        border-right: 1px solid #ddd;
        padding: 0 0.5rem;
    }

    p {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--emergency);
        padding: 0 0.5rem;
    }
}

.link {
    text-decoration: none;
}

.profile {
    cursor: pointer;
    @include flex(row, center, center);
    @include text('Poppins', normal, 0.75rem, 500, $buildingTextInput);
    @include hover(0.2s, 0.8);
    @include scale(0.2s, 0.9);
    background-color: transparent;
    gap: 0.5rem;
    margin: 0.3rem 0;
    padding: 0.2rem 1rem;
    border-radius: 0.5rem;
}

.active {
    background-color: $shadow;
}
