.root {
    width: 100%;
    height: 508px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tableContainer {
    height: calc(100% - 80px);
    border-radius: 10px;
    background: #f4f5f7;
}

.btnWrap {
    height: 30px;
    padding: 5px 0;
}

.disabled {
    opacity: .5;
}
