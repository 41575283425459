.formControlLabel {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 4px 0;

    & span {
        font-family: Poppins, sans-serif;
        font-size: 12px;
    }
}
