@import 'src/common/styles/variables';

.iconBtn {
  display: flex;
  margin: 0 1rem .2rem;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 0 12px;
  cursor: pointer;

  &:hover {
    background-color: $buildingFloor;
  }
}

.dialogButton {
  width: 10.625rem;
  margin: 1.2rem auto 0;
}
