@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.assetCard {
    width: 379px;
    height: 286px;
    flex-shrink: 0;
    background: $assetsDataBackground;
    padding: 12px;
}

.top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.avatarRow {
    display: flex;
    &__info {
        margin-left: 12px;
    }
}

.qr {
    height: 70px;
    width: 70px;
}

.box {
    width: 25.6875rem;
    background: $assetsDataBackground;
}

.noWrap {
    white-space: nowrap;
}

.row {
    gap: 2rem;
    P {
        font-style: italic;
        color: $buildingsImgGray;
    }
}
