.root {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholderWrap {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textNoData {
    margin-top: 10px;
    font-style: italic;
}
