.tableContainer {
    height: 100%;

    border-radius: 10px;
    background: #f4f5f7;
}

.flexWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
