@import 'common/styles/variables';

.root {
    height: 100%;
    display: flex;
    align-items: center;

    &.show {
        opacity: 1 !important;
    }
}

.buttonWrap {
    display: flex;
    //height: 100%; //Bug with filters/columns in tables
    padding-inline: 5px;
    box-sizing: border-box;
    height: 38px;

    align-items: center;
    justify-content: center;

    border-radius: 5px;
    //padding: 5px;

    &:hover {
        background-color: $buildingFloor;
        cursor: pointer;
    }
}

.itemContainer {
    &:hover {
        background-color: $buildingFloor;
        cursor: pointer;
    }
    height: 38px;
    display: flex;
    padding: 0px 12px;
    align-items: center;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.itemTitle {
    padding-left: 12px;
}

.iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
