.root {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 9px;
}

.infoCardsWrap {
    max-height: 40%;
    overflow: auto;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
}

.woList {
    flex-grow: 1;
}
