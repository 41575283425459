//@import-normalize;

:root {
    --primary: #1b6bc0;
    --secondary: #6c757d;
    --info: #17a2b8;
    --dark: #061b28;
    --gray: #756388;
    --light: #ffffff;
    --emergency: #dc3545;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Poppins', sans-serif;
}

body.dragging * {
    cursor: grabbing !important;
    user-select: none;
}

/*::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    margin-bottom: 0;
}

::-webkit-scrollbar-thumb {
    min-height: 0;
    border-radius: 20px;
    background-color: #848a9b;
    border: 1px solid transparent;
    background-clip: padding-box;

    &:hover {
        background-color: #848a9b;
    }
}*/
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    margin: 0;
}

@font-face {
    font-family: 'DM';
    src: local('DM'), url('./fonts/DM/static/DMSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: local('PoppinsMedium'), url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 500;
}

.Toastify__toast {
    background-color: transparent;
    padding: 0 !important;
    width: 100%;
    border-radius: 8px !important;
    box-shadow: 0 4px 27px 0 rgba(0, 19, 85, 0.1), 0 4px 10px 0 rgba(0, 19, 85, 0.2);
    height: 100% !important;
    min-height: 50px !important;
}

.Toastify__toast-body {
    padding: 0 !important;
    margin: 0;
    width: 100%;
}

.Toastify__toast-container {
    min-width: 650px;
    padding: 0 !important;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
}

.Toastify__close-button {
    align-self: initial !important;
    width: 50px;
    opacity: 1;
    background: #ffffff;
}

.Toastify__close-button > svg {
    fill: currentColor;
    height: 18px !important;
    width: 18px !important;
    opacity: 0.3;
    transition: all 0.2s, opacity 0.2s linear;

    &:hover {
        opacity: 0.7;
    }
}

.opacity {
    opacity: 0.4;
}

.vertical-center-flex {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

div {
    box-sizing: border-box;
}

.MuiMenu-paper.MuiPaper-root,
.MuiMenu-paper.MuiPopover-root {
    transform: none !important;
}

// datepicker customization
/*.react-datepicker {
    @include text('Poppins', normal, .875rem, 400, #202534);
    background-color: #fff;
    border: none;
    border-radius: 0.5rem;
    display: flex;
    display: -webkit-flex;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10), 0 0 2px 0 rgba(0, 0, 0, 0.20);
}

.react-datepicker__header {
    @include text('Poppins', 1.5rem, 1rem, 500, #202534);
    text-align: center;
    background-color: #fff;
    border-bottom: none;
    border-top-left-radius: 0.5rem;
    position: relative;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    @include text('Poppins', 1.5rem, 1rem, 500, #202534);
}

.react-datepicker__day-name {
    margin-top: 1rem;
    padding: 0 .3rem;
    @include text('Poppins', 1rem, .75rem, 400, #6D7175);
}

.react-datepicker__day, .react-datepicker__time-name {
    padding: 0 .3rem;
    @include text('Poppins', 2.25rem, .875rem, 400, #202534);
}*/

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__view-calendar-icon input::placeholder {
    font-family: Poppins, sans-serif;
    color: rgb(32, 37, 52);
    opacity: 0.4;
}

.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background-color: #c5c7cc;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #c5c7cc;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #c5c7cc;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #87939e;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #c5c7cc;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #c5c7cc;
}

.react-datepicker__close-icon::after {
    background-color: #c5c7cc;
}
