.white {
    color: white;
}

.black {
    color: #202534;
}

.grey {
    color: #848a9b;
}

.blue {
    color: #1b6bc0;
}

.red {
    color: #dc3545;
}

.light-blue {
    color: #009ef7;
}

p.tiny {
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

p.semi-bold {
    font-family: PoppinsMedium, sans-serif;
    font-weight: 500;
}

.bold {
    font-weight: 600;
}

.xxs {
    font-size: 10px;
}

.xs {
    font-size: 12px;
}

.s {
    font-size: 12px;
}

.smd {
    font-size: 14px;
}

.md {
    font-size: 16px;
}

.l {
    font-size: 18px;
}

.xl {
    font-size: 20px;
}

.xxl {
    font-size: 20px;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.p {
    font-family: 'Poppins', sans-serif;
}
