.tabPanelRoot {
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
}

.tabPanelButton {
    height: 2.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8f929a;
    font-family: PoppinsMedium, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: #202534;
    }

    &.active {
        font-family: PoppinsMedium, sans-serif;
        background: #ebebeb;
        color: #202534;
    }
}
