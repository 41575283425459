.root {
    width: 100%;
    height: 100%;
}

.historyTable {
    margin-top: 15px;
    height: calc(100% - 50px);
    flex-grow: 1;
    border-radius: 10px;
    background: #f4f5f7;
}

.status {
    display: flex;
    justify-content: center;
}
