.notificationContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 4px 20px;
    height: 100%;
    min-height: 50px;
    border-radius: .5rem 0 0 .5rem;
    background: #fff;
}

.notificationBody {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #001940;
    max-width: 75vw;
    height: 100%;
    display: flex;
    align-items: center;
}

.notificationIcon {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
}
