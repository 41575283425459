.root {
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.img {
    width: 100%;
    height: 100%;

    object-fit: contain;
}
