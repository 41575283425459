@import 'src/common/styles/variables';

.insideTooltip {
  margin-left: -5px;
  height: 38px;

  &:hover {
    background: $buildingFloor;
  }
}
