@import 'src/common/styles/variables';

.label {
    font-size: 13px !important;
    font-family: 'Poppins' !important;
    color: $buildingsFieldTitle !important;
    margin-top: 8px;
    margin-left: 10px;
}

.container {
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid $assetsDataBackground !important;
    background-color: $assetsDataBackground;
    padding: 2px 0 2px 12px !important;
    color: $buildingFloorTitle;
    font-size: 12px !important;
    margin-top: 2px;
    width: 100%;
    transition: border 0.2s;

    &.error {
        border: 1px solid $error !important;
    }
}
