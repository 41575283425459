.listContainer {
    width: 100%;

    display: grid;
    gap: 15px 24px;
    grid-template-columns: repeat(auto-fit, minmax(370px, 0.5fr));
    padding-bottom: 15px;
}

.cardContainer {
    position: relative;
    min-width: 370px;
    height: auto;
    border-radius: 10px;
    background-color: #f4f5f7 !important;
    padding: 18px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    }

    &.checked {
        outline: 1px solid #1b6bc0;
        background-color: #edf8ff !important;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.cardFlexRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.cardHeader {
    display: flex;
    gap: 12px;
    margin-bottom: 4px;
}

.cardColumnItem {
    width: 100%;
}
.cardRowItem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
