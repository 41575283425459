@import 'src/common/styles/variables';

.CheckboxInput {
  display: flex;
  justify-content: space-between;
  margin-top: .5rem;

  &_Input {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: $blueBuilding;
    width: 1.25rem;
    height: 1.25rem;
    transform: translateY(-0.075em);
    box-sizing: border-box;
    border: 1px solid $buildingsImgGray;
    border-radius: 0.1875rem;
    display: grid;
    place-content: center;
    cursor: pointer;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $blueBuilding;
      border-radius: 2px;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
