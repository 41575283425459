.radioBtnRow {
    box-sizing: border-box;
    width: 85%;
    padding: 9px 16px !important;
    border-radius: 8px;
    margin: 3px 0 !important;

    background: #f4f5f7;

    &.active {
        background: #edf8ff;
    }
}

.btnWrap {
    height: 50px;
}
