@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.box {
  background-color: $assetsDataBackground;
}

.checkBox {
  margin: 1rem .5rem 1rem 0;
}

.container {
  margin-left: 1.5rem;
}

.link {
  @include text('Poppins', normal, .875rem, 400, $buildingFloorTitle);
  @include flex(row, flex-start, center);
  gap: .5rem;
  margin-left: 10px;
  outline: none;
  text-decoration: none;
  word-break: break-word;
}
