@import 'src/common/styles/mixins';

.container {
  background-color: white;
  box-shadow: 0 20px 58px 0 rgba(9, 0, 72, 0.2);
  border-radius: 10px;
  flex-shrink: 0;
  padding: .8rem;
  width: 100%;
}

.formControl {
  width: 100%;
  margin-bottom: 20px !important;
}

.formGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: .625rem;
  border-radius: 0.5rem;
  padding: 1rem .3rem 0;
}

.checkbox {
  width: 12.5rem;
  border-radius: 0.5rem;
  background: #F4F5F7;
  padding: .25rem .7rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start!important;
  border: 1px solid transparent;
}

.checked {
  border: 1px solid #44B8FF;
}

.buttonsGroup {
  display: flex;
  justify-content: center;
}
