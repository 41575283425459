@import 'src/common/styles/tabPanel';

.container {
    width: 100%;
    box-sizing: border-box;
    min-height: 55px;
    display: flex;
    align-items: center;
    padding-inline: 11px;
}

.tabPanel {
    @extend .tabPanelRoot;
    gap: 10px;
    border: none;
}

.tabPanelButton {
    @extend .tabPanelButton;
    padding-inline: 22px;
}
