.root {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.input {
    border: none;
    border-radius: 8px;
    height: 38px;

    background: #f4f5f7;
    width: 100%;
    padding: 6px 12px 6px 42px;

    font-family: Poppins, sans-serif;
    font-size: 12px;
}

.iconWrap {
    position: absolute;
    left: 12px;

    width: 24px;
    height: 24px;

    top: 50%;

    transform: translateY(-50%);
}
