@mixin flex($flexDirection, $justifyContent, $alignItems) {
    display: flex;
    display: -webkit-flex;
    flex-direction: $flexDirection;
    -webkit-flex-direction: $flexDirection;
    justify-content: $justifyContent;
    -webkit-justify-content: $justifyContent;
    align-items: $alignItems;
    -webkit-align-items: $alignItems;
}

@mixin text($fontFamily, $lineHeight, $fontSize, $fontWeight, $color) {
    font-family: $fontFamily, sans-serif;
    line-height: $lineHeight;
    font-size: $fontSize;
    font-weight: $fontWeight;
    color: $color;
    font-style: normal;
}

@mixin hover($delay, $opacity) {
    transition: all $delay, opacity $delay linear;
    -webkit-transition: all $delay, opacity $delay linear;

    &:hover {
        opacity: $opacity;
    }
}

@mixin scale($delay, $scale) {
    transition: all $delay, scale $delay linear;
    -webkit-transition: all $delay, scale $delay linear;

    &:active {
        scale: $scale;
    }
}

@mixin scroll($width, $marginBottom, $minHeight, $borderWidth) {
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        margin-bottom: $marginBottom;
    }

    &::-webkit-scrollbar-thumb {
        min-height: $minHeight;
        border-radius: 20px;
        background-color: #848a9b;
        border: $borderWidth solid rgba(0, 0, 0, 0);
        background-clip: padding-box;

        &:hover {
            background-color: #848a9b;
        }
    }
}

@mixin transparentBackgroundColor($alpha, $color) {
    background-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin borderColorWO($border) {
    &.critical {
        border: 1px solid #dc3545;
        border-bottom: $border solid #dc3545;

        background: #ffeded;
    }
    &.high {
        border: 1px solid #ffc107;
        border-bottom: $border solid #ffc107;
    }
    &.medium {
        border: 1px solid #17a2b8;
        border-bottom: $border solid #17a2b8;
    }
    &.low {
        border: 1px solid #17a2b8;
        border-bottom: $border solid #17a2b8;
    }
    &.scheduled {
        border: 1px solid #28a745;
        border-bottom: $border solid #28a745;
    }
}

@mixin statusBadge() {
    &.yellow {
        border: 1px solid #ffc107;
        @include transparentBackgroundColor(0.1, #ffc107);
        color: #ffc107;
    }

    &.green {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.1, #28a745);
        color: #28a745;
    }
    &.red {
        border: 1px solid #f1416c;
        @include transparentBackgroundColor(0.1, #f1416c);
        color: #f1416c;
    }
    &.blue {
        border: 1px solid #1b6bc0;
        @include transparentBackgroundColor(0.1, #1b6bc0);
        color: #1b6bc0;
    }

    &.lightBlue {
        border: 1px solid #17a2b8;
        @include transparentBackgroundColor(0.1, #17a2b8);
        color: #17a2b8;
    }
    &.grey {
        border: 1px solid #6c757d;
        @include transparentBackgroundColor(0.1, #6c757d);
        color: #6c757d;
    }
}

@mixin userStatusBadge() {
    &.pending {
        border: 1px solid #ffc107;
        @include transparentBackgroundColor(0.1, #ffc107);
        color: #ffc107;
    }

    &.active {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.1, #28a745);
        color: #28a745;
    }
    &.blocked {
        border: 1px solid #f1416c;
        @include transparentBackgroundColor(0.1, #f1416c);
        color: #f1416c;
    }
}

@mixin workOrdersBadge() {
    &.newHigh {
        border: 1px solid #ffc107;
        @include transparentBackgroundColor(0.1, #ffc107);
        color: #ffc107;
    }

    &.inProgressMediumLow {
        border: 1px solid #17a2b8;
        @include transparentBackgroundColor(0.1, #17a2b8);
        color: #17a2b8;
    }

    &.completedReviewScheduled {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.1, #28a745);
        color: #28a745;
    }

    &.onHoldCanceled {
        border: 1px solid #6c757d;
        @include transparentBackgroundColor(0.1, #6c757d);
        color: #6c757d;
    }

    &.pm {
        border: 1px solid #1b6bc0;
        @include transparentBackgroundColor(0.1, #1b6bc0);
        color: #1b6bc0;
    }

    &.critical {
        border: 1px solid #f1416c;
        @include transparentBackgroundColor(0.1, #f1416c);
        color: #f1416c;
    }
}

@mixin timelineColors() {
    &.new {
        border: 2px solid #ffc107;
        @include transparentBackgroundColor(0.1, #ffc107);
    }

    &.inProgress {
        border: 1px solid #17a2b8;
        @include transparentBackgroundColor(0.2, #17a2b8);
    }

    &.completedReview {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.2, #28a745);
    }

    &.onHoldCancelled {
        border: 1px solid #6c757d;
        @include transparentBackgroundColor(0.2, #6c757d);
    }
    &.overdue {
        border: 1px solid #f1416c;
        @include transparentBackgroundColor(0.2, #f1416c);
    }
    &.pm {
        border: 1px solid #1b6bc0;
        @include transparentBackgroundColor(0.2, #1b6bc0);
    }
}

@mixin purchaseRequestsBadge() {
    &.order {
        border: 1px solid #1b6bc0;
        @include transparentBackgroundColor(0.2, #1b6bc0);
        color: #1b6bc0;
    }

    &.pending {
        border: 1px solid #f1416c;
        @include transparentBackgroundColor(0.2, #f1416c);
        color: #f1416c;
    }

    &.fulfilled {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.2, #28a745);
        color: #28a745;
    }
}

@mixin inventoryItemsBadge() {
    &.allocated {
        border: 1px solid #1b6bc0;
        @include transparentBackgroundColor(0.2, #1b6bc0);
        color: #1b6bc0;
    }

    &.available {
        border: 1px solid #28a745;
        @include transparentBackgroundColor(0.2, #28a745);
        color: #28a745;
    }

    &.transfer {
        border: 1px solid #6c757d;
        @include transparentBackgroundColor(0.1, #6c757d);
        color: #6c757d;
    }

    &.on {
        color: #ffc107;
        border: 1px solid #ffc107;
        @include transparentBackgroundColor(0.1, #ffc107);
    }
}
