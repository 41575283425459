@import 'src/common/styles/variables';

.tableToolbarRoot {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
}

.filterCount {
    background-color: $commonBlue;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    text-align: center;
    font-family: PoppinsMedium, sans-serif;
    font-size: 0.625rem;
    color: $white;
    margin: 0 0.5rem;
}

.export {
    border-radius: 8px;
    margin-bottom: 0.2rem;
    transition: background-color 0.3s;
    & p {
        font-family: PoppinsMedium, sans-serif;
    }

    &:hover {
        background-color: #eaebf1;
    }
}

.filters {
    height: 38px;
    background-color: #f4f5f7;
    border-radius: 0.5rem;
}

.flexWrap {
    display: flex;
    gap: 10px;

    &.spaceBetween {
        justify-content: space-between;
    }
}

.searchInput {
    flex: 1;
    height: 38px;
}
