@import 'src/common/styles/mixins';

.button {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    width: 100%;
    height: 38px;
    border-radius: 0.5rem;
    font-size: 12px;
    cursor: pointer;
    display: block;
    font-weight: 700;
    margin: 0 5px;
    white-space: nowrap;
    @include hover(0.2s, 0.8);
    @include scale(0.2s, 0.8);

    &[disabled] {
        opacity: 0.5;
        cursor: default;
        @include hover(0, 0.5);
        @include scale(0, 1);
    }
}

.filled-blue {
    border: 1px solid #1b6bc0;
    background: #1b6bc0;
    color: white;
}

.filled-red {
    border: 1px solid #dc3545;
    background: #dc3545;
    color: rgba(255, 255, 255, 1);
}

.filled-yellow {
    border: 1px solid #ffc107;
    background: #ffc107;
    color: black;
}

.filled-grey {
    border: 1px solid #6c757d;
    background: #6c757d;
    color: white;
}

.centerBtnContent {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 8px;
}

.center {
    justify-content: center;
}

.left {
    justify-content: left;
}

.iconWrap {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;

    svg {
        color: currentColor;
        height: 24px;
        width: 24px;
    }
}
