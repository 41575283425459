@import 'src/common/styles/mixins';

.dialog {
    @include flex(column, _, _);
    width: 100%;
    padding: 0.8rem 0.3rem 0.8rem 0.8rem;
    border-radius: 10px;
}

.close {
    position: static;
    align-self: flex-end;
    padding: 0 1.5rem 0.3rem 0;
}

.title {
    align-self: center;
    margin-top: 0.5rem;
}

.content {
    padding: 1rem;
}

.saveWrap {
    width: 100%;
}
