@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.box {
    display: inline-block;
    border-radius: 0.25rem;
    padding: 0 0.3rem;
    margin: 0.5rem 0.5rem 0 0;

    span {
        padding: 0 0.2rem;
        @include text('Poppins', normal, 0.75rem, 400, transparent);
        transition: opacity 0.2s;
    }
}

.hover > span {
    &:last-child {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}
