.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logIn {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 11px;
}

.title {
    font-family: DM, sans-serif;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 18px;
}

.inputsContainer {
    margin-bottom: 15px;
    padding-left: 25px;
}

.logInBtn {
    width: 162px !important;
    height: 42px;
}

.resend_title {
    color: #fff;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 22px;
}

.resend_link {
    font-weight: 600;
    cursor: pointer;
    &:hover {
        border-bottom: 2px solid white;
    }
}

.forgotPasswordLink {
    font-size: 12px;
    margin-top: 15px;
    color: white;
    cursor: pointer;

    &:hover {
        border-bottom: 1px solid white;
    }
}
