.root {
  width: 100%;
  padding: 0 12px;

  display: flex;
  flex-direction: column;
}

.inputsRowContainer {
  display: flex;
  justify-content: space-between;
}
