@import 'src/common/styles/variables';

.root {
    height: 100%;
}

.row {
    display: flex;
    height: 100%;
    align-items: center;
}

.label {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif !important;
    color: $buildingsFieldTitle !important;
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 2px;
}

.title {
    height: 2rem;
    background: $shadow !important;
    & p {
        line-height: 2rem;
    }
}

.item {
    padding-left: 2rem !important;
}

.itemFirst {
    display: none;
}
