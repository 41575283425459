.card {
    border-radius: 6.781px;
    background: #f4f5f7;
    min-width: 260px;
    height: 90px;
    flex-shrink: 0;
    padding: 12px 14px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}
