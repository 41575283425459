.pageContainer {
    background-position: center;
    background-image: url('/assets/img/bg.jpg');
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
    height: 100vh;
}

.logo {
    display: flex;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 50px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
