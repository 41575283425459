.root {
    width: 300px;
    height: 400px;
    overflow: hidden;
}

.header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;

    background: #f4f5f7;
}

.notificationRoot {
    width: 100%;
    border-bottom: 0.5px solid #ddd;
    padding: 7px 14px;
    background: #e9eef3;
    cursor: pointer;

    &.viewed {
        background: #ffffff;
    }
}

.notificationTitle {
    display: flex;
    align-items: center;

    gap: 10px;
}

.notificationList {
    overflow-y: auto;
    height: calc(100% - 40px);
    position: relative;
}

.loading {
    position: absolute;
    top: 10px;
    left: 50%;
}
