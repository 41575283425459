@import 'src/common/styles/mixins';

.summaryContent {
    width: 100%;
    @include flex(row, none, center);
    gap: 14px;

    font-family: PoppinsMedium, sans-serif;
    font-size: 12px;

    color: #202534;

    span {
        flex: 1;
    }
}

.loading {
    max-width: 20px;
    margin-right: 1rem;
}

.accordionIcon {
    width: 13px;
    height: 13px;

    transform: rotate(90deg);
}
