.sideOuter {
    position: relative;
}

.sideNavContainer {
    //position: relative;
    background-color: #1e1e2d;
    width: 310px;
    min-height: 100vh;
    max-height: 100vh;
    color: var(--light);
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 2;
}

.sideNavContainerNX {
    width: 75px;
}

.navUpper,
.navHeading,
.navMenu,
.menuItem {
    display: grid;
}

.navHeading {
    height: 155px;
}

.navBrand,
.navMiniBrand {
    display: flex;
    color: var(--light);
    width: 100%;
}

.navBrand img {
    width: 100%;
    padding: 16px 45px 38px;
}

.navMiniBrand img {
    width: 40px;
    height: 60px;
    margin: 25px auto 0;
}

.hamburger {
    border: white;
    top: 4px;
    position: absolute;
    right: -16px;
    cursor: pointer;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 10px;
    height: 32px;
    width: 32px;
    //opacity: 0.5;
    transition: opacity 0.4s;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.25);
    z-index: 5;
    &:hover {
        opacity: 1;
    }
}

.hamburger span {
    display: block;
    margin-top: 2px;
    background-color: grey;
    border-radius: 15px;
    height: 3px;
    transition: 0.4s;
}

.hamburgerIn span:nth-child(1) {
    width: 20px;
    transform: translate(0px, 7px) rotate(30deg);
}

.hamburgerIn span:nth-child(2) {
    display: none;
}

.hamburgerIn span:nth-child(3) {
    width: 20px;
    transform: translate(0px, -7px) rotate(-30deg);
}

.hamburgerOut span:nth-child(1) {
    width: 20px;
    transform: translate(2px, 7px) rotate(-30deg);
}

.hamburgerOut span:nth-child(2) {
    display: none;
}

.hamburgerOut span:nth-child(3) {
    width: 20px;
    transform: translate(2px, -7px) rotate(30deg);
}

.devider {
    background-color: #dddddd;
    margin: 0 3px;
    height: 1px;
    opacity: 0.2;
}

.navMenu {
    grid-template-rows: repeat(9, 1fr);

    &_menuItem {
        cursor: pointer;
        height: 42px;
        width: 100%;
        display: flex;

        text-decoration: none;
        color: var(--light);
        opacity: 0.5;
        gap: 15px;
        transition: 0.4s;
        align-items: center;
    }
}

.menuItem {
    cursor: pointer;
    height: 42px;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--light);
    opacity: 0.5;
    gap: 15px;
    transition: 0.4s;
    align-items: center;
}

.menuItemActive {
    @extend .menuItem;
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
}

.menuItem:nth-child(1) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.menuItem:nth-last-child(3) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.menuItem:hover {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
}

.navIcon {
    margin-left: 1.12rem;
    height: 25px;
    width: auto;
}

.navText {
    font-size: 12px;
    white-space: nowrap;
}

.navBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
    padding: 0 0.5rem;
}
