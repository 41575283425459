.root {
    width: 100%;
    padding: 13px 12px;
    font-family: Poppins, sans-serif;

    display: flex;

    background: #f4f5f7;
    margin: 4px 0;
    border-radius: 10px;
}

.content {
    flex: 1;
}

.headerNote {
    margin-bottom: 2px;

    display: flex;

    .name {
        font-family: PoppinsMedium, sans-serif;
        color: #202534;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-right: 10px;
    }

    .date {
        color: #848a9b;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }
}

.textNote {
    color: #202534;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.tooltip {
    align-items: flex-start;
}
