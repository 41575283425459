@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.checkBoxWrap {
    position: absolute;
    right: 5px;
    top: 0;
}

.box {
    width: 100%;
    @include flex(row, flex-start, center);
    flex-wrap: wrap;
    gap: 0.2rem 0.5rem;
    padding: 0.1rem;
}

.type {
    @include flex(row, center, center);
    gap: 0.5rem;

    img {
        width: 30px;
        height: 30px;
    }
}
