.root {
    width: 450px;
    height: 100%;

    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.content {
    padding: 30px 17px 10px 17px;

    flex-grow: 1;
    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 15px;
}

.historyCardRoot {
    width: 100%;
    padding: 14px 20px;

    background: #f4f5f7;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 6px;
}

.historyRow {
    display: flex;
}

.historyRowTitle {
    width: 50%;
}

.historyRowValue {
    width: 50%;
}
