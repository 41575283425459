.root {
    width: 450px;
    height: 100%;

    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.rowInfoNotify {
    display: flex;
    gap: 5px;

    svg {
        color: #dc3545;
    }
}

.formContent {
    padding: 30px 17px 10px 17px;

    flex-grow: 1;
    overflow: auto;
}

.text {
    word-wrap: break-word;
    margin-bottom: 10px;
}

.label {
    word-wrap: break-word;
    margin-bottom: 10px;
    margin-top: 10px;
}

.input {
    background: #f4f5f7;
}

.checkbox {
    margin: 15px 0;
}
