@import 'src/index.scss';
@import 'src/common/styles/variables';

.root {
    height: 100%;
}

.iconItem {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 15px;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
}

.description {
    width: 25rem;
    white-space: break-spaces;
}

.iconItem {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 15px;
}

.row {
    display: flex;
    height: 100%;
    align-items: center;
}

.label {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif !important;
    color: $buildingsFieldTitle !important;
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 2px;
}
