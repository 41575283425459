:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

.inputLabel {
    color: white;
    font-size: 13px;
    line-height: 1.5;
}

.errorContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}

.inputBorder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #5b5b70;
    border-radius: 8px;
    width: 360px;
    height: 38px;
    padding-right: 10px;
}

.inputClassName {
    display: flex;
    width: 100%;
    padding: 8px 20px 9px 13px;
    align-items: center;
    border-radius: 8px;
    background: #5b5b70;
    border: none;
    //color: #d5d5d5;
    color: white;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #5b5b70;
}
input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
}

.passwordInput {
    font-family: text-security-disc, sans-serif;
    -webkit-text-security: disc !important;
}
