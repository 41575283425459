@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.root {
    width: 100%;
}

.filesWrap {
    max-height: 100px;

    overflow: auto;

    & > div:first-child {
        margin-top: 10px;
    }
}

.fileRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
    cursor: pointer;

    a {
        flex: 1;
        outline: none;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #202534;
    }

    span {
        flex: 1;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .fileSize {
        color: #848a9b;
        margin-inline: 10px 5px;
    }

    .delete {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.dropZone {
    width: 100%;
    height: 116px;
    border-radius: 10px;
    border: 1px dashed #848a9b;
    background: #f4f5f7;
    position: relative;

    .dropZoneContent {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: #848a9b;
        cursor: pointer;

        svg {
            width: 40px;
            height: 40px;
            color: #000000;
        }
    }
}

.link {
    @include text('Poppins', normal, 0.75rem, 400, $buildingFloorTitle);
    @include flex(row, flex-start, center);
    gap: 1rem;
    outline: none;
    text-decoration: none;
    word-break: break-word;
}

.flyBox {
    background: transparent;
    min-width: 40rem;
    max-width: 80vw;
}

.flyImg {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    border-radius: 10px;
}
