.container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.input {
    display: none;
}

.labelWrapper {
    cursor: pointer;
    background-color: #e2e6eb;
    height: 180px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 11px;
    border: 2px solid #ffffff;

    span {
        font-size: 13px;
        color: #848a9b;
    }

    svg * {
        stroke: #848a9b;
    }
}

.imageWrapper {
    height: 180px;
    width: 180px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 11px;
    }

    &:hover {
        & .change_label.hover {
            display: flex;
        }
    }
}

.change_label {
    cursor: pointer;
    width: 100%;
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(32, 37, 52, 0.6);

    justify-content: center;
    align-items: center;

    height: 38px;
    color: #ffffff;
    font-size: 13px;
}
