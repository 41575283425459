.root {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardsWrap {
    padding: 2px;
    height: calc(100% - 30px);
    display: grid;
    gap: 15px 24px;
    grid-template-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    overflow-y: auto;
}

.dialog {
    background: #e9eef3;
}

.row {
    align-items: center;
}

.italic {
    font-style: italic;
}

.radioBtnRow {
    box-sizing: border-box;
    width: 85%;
    padding: 9px 16px !important;
    border-radius: 8px;
    margin: 3px 0 !important;
    &.active {
        background: #edf8ff;
    }
}

.btnWrap {
    height: 50px;
}

.dialog {
    background: #e9eef3;
}
