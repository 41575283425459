@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.checkBox {
    background-color: $buildingInput;
    border-radius: 0.5rem;
    padding: 0.6rem 0.9rem;
    box-sizing: border-box;
}

.details {
    @include flex(column, flex-start, flex-start);
}

.row {
    @include flex(row, flex-start, center);
    gap: 0.4rem;
}

.dot {
    border: 2px solid $buildingsImgGray;
    transform: rotate(90deg);
    border-radius: 2px;
}
