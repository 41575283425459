.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding: 9px 11px;
    gap: 10px;
}

.tableContainer {
    flex-grow: 1;
    overflow: hidden;
}
