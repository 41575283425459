@import 'src/common/styles/variables';

.showMore {
  &_text {
    text-align: center;
    color: $blueBuilding;
    cursor: pointer;
    height: 1.8rem;

    &:hover {
      opacity: .7;
    }
  }
}
