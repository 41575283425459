.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    width: 100%;
    border-top: 1px solid #f2f6f8;

    display: flex;
    overflow: hidden;
}

.container {
    width: 100%;
    height: 100%;
    padding: 9px;
}

.breadcrumbs {
    height: 2.5rem;
    border-bottom: 1px solid #f2f6f8;
    display: flex;
    align-items: center;
}
