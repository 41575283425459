@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.root {
    padding: 12px 16px;
    border-radius: 10px;
    cursor: default;
    position: relative;

    @include borderColorWO(30px);
}

.headerCard {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    margin-bottom: 16px;

    img {
        width: 36px;
        height: 36px;
    }
}

.cardNameWrap {
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
        width: 100%;
        color: #202534;
        font-family: PoppinsMedium, sans-serif;
        font-size: 16px;
    }

    .priority {
        display: flex;
        gap: 2px;
    }
}

.description {
    margin-bottom: 5px;
    word-break: break-word;
}

.flexRow {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    margin-bottom: 9px;
}

.flexItem {
    width: 100%;
}

.viewMoreWrap {
    position: absolute;
    width: 100%;
    bottom: -25px;
    left: 0;
    cursor: pointer;

    display: flex;
    justify-content: center;
}

.buckets {
    &:after {
        content: ', ';
    }

    &:last-child {
        &:after {
            content: '';
        }
    }
}
