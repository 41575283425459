@import 'common/styles/mixins';

.root {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;

    font-family: PoppinsMedium, sans-serif;
    font-size: 10px;
    line-height: 100%;
    border-radius: 4px;

    white-space: nowrap;

    @include statusBadge();
}
