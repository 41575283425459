.root {
    width: 100%;
    height: 70px;

    display: flex;
    padding: 16px 0;

    gap: 10px;

    & > button {
        max-width: 50%;
    }
}
