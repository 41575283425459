@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.container {
    @include flex(column, flex-start, flex-start);
    gap: 0.5rem;
}

.loading {
    position: absolute;
    top: 10px;
    left: 50%;
}

.dialog {
    background: #e9eef3;
}