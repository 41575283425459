.clear {
  position: absolute;
  //top: 3px;
  right: 25px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #e5e7ea;
  }
}
