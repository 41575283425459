.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fileList {
    height: 100%;
    overflow: hidden;

    & > section {
        height: 100% !important;
    }
}
