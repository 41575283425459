.root {
    width: 450px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.content {
    width: 100%;
    flex-grow: 1;
    padding: 43px 19px 19px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.nameWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contactButtonWrap {
    width: 100%;
    display: flex;
    gap: 10px;
}

.contactButton {
    padding: 5px 0;
    width: 100%;
    border-radius: 8px;
    background: #e2e6eb;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    text-decoration: none;
}

.contactButtonContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.infoWrap {
    width: 100%;
}

.saveWrap {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-inline: 30px;

    flex-grow: 0;
    flex-shrink: 0;

    background: #f4f5f7;
    border-top: 1px solid #dddddd;
}
