@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.hiddenBox {
    // box for send to email
    position: absolute;
    top: -10000px;
    margin: 0 auto;
}

.logo {
    width: 24.3125rem;
    height: 4.5625rem;
    margin: 0 5.2rem 1.6rem;
    @include flex(row, center, center);
}

.woNumber {
    margin: 0.3rem 0 0.3rem;
}

.titleBox {
    @include flex(row, space-between, flex-start);
    margin-top: 0.3rem;
}

.supervisorTitle {
    @include flex(row, flex-end, center);
}
.supervisorRow {
    @include flex(row, flex-start, flex-start);
}

.table {
    margin-top: 1.6rem;
}

.tableHeader {
    height: 1.5625rem;
}

.blue {
    background: #1280e0;
}

.grey {
    background: #ddd;
}

.cell {
    padding: 0;
    font-size: 8px;
}

.fileBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1.1rem;
}

.noteFileBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 1.1rem;
}

.fileContainer {
    width: 100%;
    height: auto;
    border: 0.5px solid #ddd;
    padding: 8px;
    box-sizing: border-box;
    @include flex(row, center, center);
}

.noteFileContainer {
    width: 100%;
    height: auto;
    padding: 8px;
    box-sizing: border-box;
    @include flex(row, center, center);
}

.file {
    width: 100%;
    object-fit: fill;
}

.fileUrl {
    word-break: break-all;
    font-size: 7px;
    padding: 8px;
}

.noteBox {
    margin-top: 0.5rem;
}

.noteContainer {
    width: 100%;
    border: 0.5px solid #ddd;
    @include flex(column, flex-start, flex-start);
    padding: 9px 11px;
}

.noteHeader {
    @include flex(row, flex-start, center);
}

.container {
    margin-top: 1.3rem;
}
