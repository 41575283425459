.pageContainer {
    display: flex;
}

.childrenContainer {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.child {
    height: calc(100vh - 2.625rem); // - internal header
}
