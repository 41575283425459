.root {
    width: 100%;
}

.cardsWrap {
    overflow: auto;
    grid-template-rows: min-content;
    height: 100%;
}

.emailItem {
    max-width: 48%;
    & p {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
