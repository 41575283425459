@import 'src/common/styles/variables';

:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

.label {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif !important;
    color: $buildingsFieldTitle !important;
    margin-top: 8px;
    margin-left: 10px;
}

.datePickerContainer {
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: $buildingInput;
    min-height: 38px;
    padding: 3px 12px 3px 38px !important;
    color: $buildingFloorTitle;
    font-size: 12px !important;
    margin-top: 2px !important;
    width: 100% !important;
    transition: border 0.2s;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-background-clip: text;
        border: none;
        box-shadow: inset 0 0 20px 30px $buildingInput;
        -webkit-text-fill-color: $buildingFloorTitle !important;
    }

    &.error {
        border: 1px solid $error;
    }
}
