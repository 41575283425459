.root {
    width: 450px;
    height: 100%;

    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.flexInputRow {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.select {
    width: 50%;
}

.autocomplete {
    background: #e2e6eb;
}

.autocompleteLocation {
    background: #ffffff;
}

.collapsedRow {
    background: #e9eef3;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 15px;

    & :global(.MuiAccordionDetails-root) {
        background: #f1f1f1;
        padding: 0 10px 10px 10px;
    }
}

.checkboxWrap {
    margin-top: 15px;
}

.formContent {
    padding: 30px 17px 10px 17px;

    flex-grow: 1;
    overflow: auto;
}

.documentsTitle {
    margin: 10px 0;
}

.saveWrap {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-inline: 30px;

    flex-grow: 0;
    flex-shrink: 0;

    background: #f4f5f7;
    border-top: 1px solid #dddddd;
}

.title {
    margin-top: 15px;
}

.locationRoot {
    background: #f1f1f1;
}

.datePicker {
    background: #ffffff;
}
