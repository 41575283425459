.root {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.headerRow {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-bottom: 10px;
}
