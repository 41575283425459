.root {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.threeWrap {
    margin-top: 10px;
    background: #f4f5f7;
    border-radius: 8px;
    overflow: auto;
    height: calc(100vh - 400px);
}

.saveWrap {
    width: 100%;
    background: #ffffff;
    position: sticky;
    left: 0;
    bottom: 0;
}

.accordionRowWrap {
    width: 100%;
    display: flex;
    gap: 14px;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.detailWrap {
    flex: 1;

    .name {
        color: var(--text, #202534);
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }

    .amount {
        color: var(--text, #a8acb4);
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }
}

.assetRow {
    padding: 5px 10px 5px 60px;

    &.checked {
        background: #ecf8ff;
    }

    &.search {
        padding-left: 20px;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.assetCheckBox {
    & svg {
        height: 18px;
        width: 18px;
    }
}

.accordionIcon {
    width: 13px;
    height: 13px;

    transform: rotate(90deg);
}

.progress {
    background-color: transparent;
}
