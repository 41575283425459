@import 'src/common/styles/variables';

:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

.label {
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif !important;
    color: $buildingTextInput;
    width: 100%;
}

.datePickerContainer {
    box-sizing: border-box;
    background-color: transparent;
    height: 24px;
    width: 24px;
    color: $buildingsFieldTitle;
    transition: border 0.2s;
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        -webkit-background-clip: text;
        border: none;
        box-shadow: inset 0 0 20px 30px $buildingInput;
        -webkit-text-fill-color: $buildingFloorTitle !important;
    }

    &.error {
        color: $error;
    }
}
.error {
    color: $error;
}
.root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
