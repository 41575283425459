.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logIn {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 11px;
}

.title {
  font-family: DM, sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 18px;
}

.inputsContainer {
  margin-bottom: 25px;
  padding-left: 25px;
}

.logInBtn {
  width: 162px !important;
  height: 42px;
}

.forgotPasswordLink {
  margin-top: 15px;
  color: white;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    border-bottom: 1px solid white;
  }
}

