@import 'src/index.scss';
@import 'src/common/styles/variables';

.iconItem {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 15px;
}

.labelWrap {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: $buildingsFieldTitle;
    margin-top: 8px;
    margin-left: 10px;
}
