.root {
    height: 100%;
    display: flex;

    align-items: center;
}

.picker {
    z-index: 10000;
}

.selectWeek {
    & :global(.react-datepicker__week--selected),
    & :global(.react-datepicker__week.react-datepicker__week--selected:hover) {
        background: #c5c7cc;
        border-radius: 5px;
    }

    & :global(.react-datepicker__day:hover),
    & :global(.react-datepicker__day--keyboard-selected) {
        background: transparent;
    }

    & :global(.react-datepicker__week:hover) {
        background: #f0f0f0;
        border-radius: 5px;
    }
}

.pickerWrap {
    width: 200px;
}

.btn {
    width: 24px;
    height: 24px;

    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    &.prev {
        transform: rotate(180deg);
    }
}

.date {
    width: 200px;
    user-select: none;

    cursor: pointer;
    &:hover {
        color: #1b6bc0 !important;
    }
}

.today {
    margin-left: 15px !important;
}
