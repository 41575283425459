.root {
    position: relative;
    display: flex;
    flex-direction: column;
}

.noteContent {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
}
