.table {
    border-collapse: collapse !important;
    border-spacing: 0;
    height: 0;
    width: 100%;
    font-family: Poppins, sans-serif !important;
    padding: 0;

    overflow: auto;
}

.tableHeadData {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    min-width: 25px;
    font-family: Poppins, sans-serif !important;

    border-right: 1px solid #ddd !important;

    &.borderHide {
        border: none !important;
    }
}

.tableBodyData {
    @extend .tableHeadData;

    font-weight: 400;
    font-size: 13px;
    min-width: 25px;
    word-wrap: anywhere;
}

.sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    box-shadow: -3px 0px 0px 0px #dddddd inset;

    & td,
    & th {
        border: none !important;
    }
}

.stickyHead {
    @extend .sticky;
    z-index: 5 !important;

    & > span {
        width: 100%;
    }
}

.tableHeadRow {
    vertical-align: center;
    height: 40px;
    background-color: #e2e6eb;

    border-bottom: 1px solid #ddd;
    font-family: Poppins, sans-serif !important;
}

.tableBodyRow {
    border-bottom: 1px solid #ddd;
    background-color: #f4f5f7;
    font-family: Poppins, sans-serif !important;

    cursor: pointer !important;
    transition: background-color 0.2s;
    height: 38px;

    &:nth-of-type(odd) {
        background: #f0f0f0;
    }

    &:hover {
        background-color: #e2e6eb !important;
    }

    &.active {
        background-color: #ecf8ff !important;
    }
}
