.root {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.container {
    padding: 15px;
    max-height: 50%;
    flex-grow: 1;
}
