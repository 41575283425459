$buildingsImgGray: #848a9b;
$white: #ffffff;
$buildingsFieldTitle: #87939e;
$buildingInput: #e2e6eb;
$buildingTextInput: #202534;
$buildingFloor: #e9eef3;
$buildingFloorTitle: #202534;
$shadow: #e0dfe8;
$blueBuilding: #1b6bc0;
$assetsDataBackground: #f4f5f7;

$error: #f1416c;
$errorBackground: rgba(241, 65, 108, 0.1);
$warning: #ffc107;
$warningBackground: rgba(255, 193, 7, 0.1);
$success: #28a745;
$successBackground: rgba(40, 167, 69, 0.1);
$info: #17a2b8;
$infoBackground: rgba(23, 162, 184, 0.1);
$commonBlue: #1b6bc0;
$commonBlueBackground: rgba(27, 107, 192, 0.1);
$boxBackground: rgba(0, 158, 247, 0.1);
$high: #ff7e07;
$highBackground: rgba(255, 126, 7, 0.1);
$low: #9c4bdd;
$lowBackground: rgba(180, 130, 220, 0.1);
