.root {
    width: 14px;
    height: 14px;

    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;

    color: #848a9b;
    z-index: 1;

    &:hover {
        & svg {
            color: #000000;
        }
    }
}
