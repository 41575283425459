.flexWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.td {
    white-space: nowrap;
}

.tableContainer {
    height: 100%;
    border-radius: 10px;
    background: #f4f5f7;
}

.menuItem {
    width: 100%;
    justify-content: center;
}

.tooltipHeader {
    margin: 10px 0;
}

.buildingsTooltip {
    width: 100px;
}

.buildingsTooltipRow {
    width: 415px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    gap: 5px;

    cursor: pointer;

    .nameAddress {
        flex-grow: 1;
    }

    &:hover {
        background: #e9eef3;
    }
}
