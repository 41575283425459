.root {
    width: 454px;
    height: 100vh;

    overflow: hidden;
    padding: 10px 10px 10px 20px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.woList {
    overflow: auto;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    margin-top: 15px;
    padding-right: 5px;
}
