@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.link {
    width: 100%;
    margin-top: 1rem;
    @include flex(row, center, center);
}

.drop {
    width: 400px;
}

.button {
    width: 12.625rem;
    margin: 1.5rem auto 0;
}

.input {
    padding: 0 12px !important;
    min-height: 2rem;
}

.datePicker {
    background-color: transparent !important;
}

.checkBox {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.error {
    border: 2px solid $error;
}

.errorTitle {
    border-bottom: 1px solid $error !important;
    color: $error !important;
}

.unexpectedTitle {
    border-bottom: 1px solid black !important;
    color: black !important;
    & :global(:hover) {
        color: black !important;
    }
}

.theadTitle {
    pointer-events: none;
}

.table {
    height: 75vh;
    @include scroll(5px, 0, 100px, 1px);
}

.select {
    height: 2rem;
    margin-top: 0.2rem;
    min-width: 8rem;
}

.buttonsGroup {
    height: 4.375rem;
    width: 100%;
    box-sizing: border-box;
    @include flex(row, center, center);
    gap: 0.625rem;
}
