@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.form {
    width: 28.375rem;
    height: 100vh;
    @include flex(column, space-between, flex-start);
}

.container {
    width: 100%;
    padding: 2.525rem 0.6rem 2.525rem 1.2rem;
    height: calc(100vh - 4.375rem);
    @include scroll(9px, 0, 0, 3px);
}

.title {
    text-align: center;
    margin: 0.5rem 0;
}

.box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.select {
    height: auto;
    margin-top: 0.5rem;

    & p {
        font-size: 12px;
        margin-left: 10px !important;
        padding-right: 1rem;
    }
}

.autoComplete {
    background-color: $assetsDataBackground;
}

.textButton {
    margin-top: 0.5rem;
}

.checkBox {
    margin: 1rem 0.5rem 1rem 0;
}

.empty {
    margin-top: 0.5rem;
    border-bottom: 1px solid #ddd;
}

.row {
    margin: 0.5rem 0 0.5rem;
    border-radius: 0.3125rem;
    background: $buildingInput;
    padding: 0.5625rem 0.5rem;

    & svg {
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 5px;
    }
}

.info {
    width: 32px;
}

.date {
    background-color: $assetsDataBackground;
}

.locationTitle {
    margin-top: 1.5rem;
}

.buttonsGroup {
    height: 4.375rem;
    width: 100%;
    box-sizing: border-box;
    @include flex(row, center, center);
    gap: 0.625rem;
    background: #f2f2f2;
    border-top: 1px solid #ddd;
}

.button {
    width: 12.625rem;
}

.note {
    min-height: 4.75rem;
}

.duplicateBox {
    max-height: 60vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, 410px);
    width: 100%;
    gap: 0.5rem;

    @include scroll(9px, 0, 0, 3px);
}

.duplicateTitle {
    margin-bottom: 1rem;
    text-align: center;
}

.description {
    white-space: normal !important;
}
