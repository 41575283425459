@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.fileContainer {
    margin-top: 0.8rem;
    width: calc(100% - 3.4rem);
    min-height: calc((100vh - 250px) / 2);
    height: calc((100vh - 250px) / 2);
    border-radius: 0.3125rem;
    background: $assetsDataBackground;
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    padding: 1.7rem;
    gap: 1.7rem;
    @include scroll(11px, 0, 0, 4px);
}

.fileBox {
    text-align: center;
}

.titleBox {
    @include flex(row, center, center);
    flex-wrap: nowrap;
    margin-top: 0.56rem;
    box-sizing: border-box;
    width: 10.75rem;
}

.link {
    margin-left: 10px;
    word-break: break-word;
    cursor: pointer;
}

.box {
    background: $white;
    width: 10.75rem;
    height: 10.75rem;
    cursor: pointer;
}

.iconBox {
    padding: 3rem;
}

.img {
    width: 10.75rem;
    height: 10.75rem;
    object-fit: contain;
    box-sizing: border-box;
    border-radius: 10px;
}

.flyBox {
    background: transparent;
    min-width: 40rem;
    max-width: 80vw;
}

.flyImg {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    border-radius: 10px;
}
