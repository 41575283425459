@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.status {
    @include flex(row, flex-end, center);
    gap: 0.3rem;
    flex: 1;
}

.inProgress {
    border: 1px solid #1b6bc0;
    @include transparentBackgroundColor(0.1, #1b6bc0);
    color: #1b6bc0;
    border-radius: 0.3125rem;
    padding: 0 0.3rem;
}

.onHold {
    border: 1px solid #ff7e07;
    @include transparentBackgroundColor(0.1, #ff7e07);
    color: #ff7e07;
    border-radius: 0.3125rem;
    padding: 0 0.3rem;
}

.pending {
    border: 1px solid #ffc107;
    @include transparentBackgroundColor(0.1, #ffc107);
    color: #ffc107;
    border-radius: 0.3125rem;
    padding: 0 0.3rem;
}

.approved {
    display: inline-block;
    border-radius: 0.25rem;
    border: 1px solid $success;
    background: $successBackground;
    padding: 0 0.5rem;
    @include text('Poppins', normal, 0.75rem, 400, $success);
    white-space: nowrap;
}
