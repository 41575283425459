@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.root {
    width: 100%;
    height: 100%;
}

.middleCard {
    @include flex(column, flex-start, flex-start);
    gap: 0.2rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-left: 0.375rem solid #28a745;
    padding: 0.2rem 0.4rem;

    &_title {
        width: 100%;
        @include flex(row, space-between, flex-start);
        height: 2.5rem;
        gap: 0.5rem;

        &_box {
            @include flex(row, flex-start, center);
            height: 2.5rem;
            gap: 0.8rem;
            position: relative;

            &_refresh {
                width: 1.2rem;
                height: 1.2rem;
                padding: 0.0625rem 0.0625rem 0.0625rem 0;
                border-radius: 0.25rem;
                position: absolute;
                left: 1.2rem;
                bottom: -0.0625rem;
                display: flex;
                justify-content: center;
                align-items: center;

                &:disabled {
                    opacity: 1;
                    cursor: default;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &_name {
                font-family: 'PoppinsMedium';
            }
        }

        &_assign {
            border-radius: 0.3125rem;
            border: 1px solid $commonBlue;
            background: rgba(27, 107, 192, 0.1);
            padding: 0.0625rem 0.4375rem;
            @include text(Poppins, normal, 0.75rem, 400, $commonBlue);
            margin-right: 0;
        }
    }

    &_user {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
    }
}
