@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.root {
    background-color: white;
    padding: 0.5rem;
    height: 100%;
    width: 800px;
}

.container {
    width: 100%;
    margin-top: 0.8rem;
    max-width: 840px;

    display: grid;
    gap: 10px 5px;
    grid-template-columns: repeat(3, max-content);

    align-items: end;

    padding: 0.4rem 0.3rem;
}

.row {
    width: 100%;
    @include flex(row, flex-start, center);
    gap: 0.625rem;
}

.buttonsGroup {
    height: 4.375rem;
    box-sizing: border-box;
    @include flex(row, flex-end, center);
    gap: 0.625rem;
    padding-right: 1rem;
}

.button {
    width: 9.75rem;
}

.autocomplete {
    width: 250px;
    height: 40px;
}

.select {
    width: 250px;
    height: 67px;

    & div {
        height: 40px;
    }
}

.checkboxWrap {
    width: 250px;
    border-radius: 0.5rem;
    background: $assetsDataBackground;
    padding: 0.25rem 0.7rem;
}

.datePicker {
    background-color: $assetsDataBackground !important;
}
